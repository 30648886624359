import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import Glide from "@glidejs/glide";
import Driver from "driver.js";
import flatpickr from "flatpickr";
import LazyLoad from "vanilla-lazyload";
import { Dropdown, Modal, Collapse, Tab, Tooltip } from "bootstrap";
import Inputmask from "inputmask";
import IMask from 'imask';
import Select2 from "select2";
import * as ActiveStorage from "@rails/activestorage";
import "modal-video/js/jquery-modal-video"
import "channels";


Rails.start();
Turbolinks.start();
ActiveStorage.start();

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);
require("select2/dist/css/select2");
import "../stylesheets/main.scss";

const jQuery = require("jquery");
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually referring to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

$(document).on("turbolinks:load", function () {
  // Initialize lazy loading
  if ($(".js-lazy:not(.loaded)").length > 0) {
    global.lazyLoadInstance = new LazyLoad({
      elements_selector: ".js-lazy:not(.loaded)",
    });
  }
  
  
  
  const menuLinks = document.querySelectorAll('a.menu__link');
  const footerLinks = document.querySelectorAll('a.footer__link');
  if (menuLinks.length > 0 && footerLinks.length > 0) {
    menuLinks.forEach(link => {
      link.addEventListener("click", function(event) {
        if (link.getAttribute('href').startsWith('/')) {
          event.preventDefault(); // Prevent the default action if needed
          window.scrollTo({ top: 0 });
          window.location.href = link.getAttribute('href');
        }
      });
    });
    footerLinks.forEach(link => {
      link.addEventListener("click", function(event) {
        if (link.getAttribute('href').startsWith('/')) {
          event.preventDefault(); // Prevent the default action if needed
          window.scrollTo({ top: 0 });
          window.location.href = link.getAttribute('href');
        }
      });
    });
  }

  $(".js-open-menu").on("click", function (e) {
    e.preventDefault();
    $(".menu").addClass("menu--shown");
    $(".sidebar").addClass("sidebar--shown");
    $(".site-overlay").addClass("site-overlay--shown");
  });
  $(".js-close-menu").on("click", function (e) {
    e.preventDefault();
    $(".menu").removeClass("menu--shown");
    $(".sidebar").removeClass("sidebar--shown");
    $(".site-overlay").removeClass("site-overlay--shown");
  });

  if ($(".js-back").length > 0) {
    $(".js-back").on("click", function (e) {
      e.preventDefault();
      window.history.back();
    });
  }

  $(window).on("resize", function () {
    $("html").addClass("resize-animation-stopper");
    return setTimeout(function () {
      $("html").removeClass("resize-animation-stopper");
    }, 300);
  });

  if ($(".js-glide-growing-clients").length > 0) {
    new Glide(".js-glide-growing-clients", {
      type: "carousel",
      autoplay: 4000,
      perView: 5,
    }).mount();
  }

  if ($(".js-glide-blog").length > 0) {
    new Glide(".js-glide-blog", {
      type: "slider",
      autoplay: 4000,
    }).mount();
  }

  if ($(".js-glide-dashboard").length > 0) {
    new Glide(".js-glide-dashboard", {
      type: "slider",
      autoplay: 4000,
      arrows: false,
    }).mount();
  }

  if ($(".js-contact").length > 0) {
    var selector = document.querySelectorAll(".js-contact");
    Inputmask({
      mask: "+63 999 999 9999",
      clearMaskOnLostFocus: false,
      placeholder: "_",
    }).mask(selector);
  }

  if ($(".js-sme-mobile").length > 0) {
    var selector = document.querySelectorAll(".js-sme-mobile");
    Inputmask({
      mask: "9999-999-9999",
      clearMaskOnLostFocus: false,
      clearIncomplete: true,
    }).mask(selector);
  }

  if ($(".js-sme-dob").length > 0) {
    var selector = document.querySelectorAll(".js-sme-dob");
    Inputmask({
      clearMaskOnLostFocus: false,
    }).mask(selector);
  }

  if ($(".js-city-select2").length > 0) {
    $(".js-city-select2").select2({
      placeholder: "Select area or location",
      dropdownParent: $("#citySelect2"),
    });

    $(document).on("change", ".js-city-select2", function (e) {
      var $this, city_id;
      e.preventDefault();
      $this = $(this);
      city_id = $this.val() === "" ? 0 : $this.val();
      $.ajax({
        url: "/site/json/retrieve_hospitals",
        type: "POST",
        data: {
          city_id: city_id,
        },
        dataType: "json",
        success: function (data) {
          var hospital_select, options;
          hospital_select = $(".js-hospital-select2");
          hospital_select.empty();
          $.each(data.hospitals, function (i, hospital) {
            options +=
              '<option value="' +
              hospital.name +
              '">' +
              hospital.name +
              "</option>";
          });
          hospital_select.attr("disabled", false);
          return hospital_select.append(options);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          return alert("Error encountered: " + errorThrown);
        },
      });
    });
  }

  if ($(".js-hospital-select2").length > 0) {
    $(".js-hospital-select2").select2({
      dropdownParent: $("#hospitalSelect2"),
    });
  }

  if ($(".js-select-page").length > 0) {
    $(".js-select-page").on("change", function () {
      var selectedBenefit = this.value;
      localStorage.setItem('selectedBenefit', selectedBenefit);
      var url = $(this).val(); // get selected value
      if (url) {
        // require a URL
        window.location = url; // redirect
      }
      return false;
    });
  }

  if ($(".js-select-tab").length > 0) {
    $(".js-select-tab").on("change", function () {
      var url = $(this).val();
      $(".nav-tabs li button[data-bs-target='" + url + "']").tab("show");
    });
  }

  if ($(".js-check").length > 0) {
    $(".js-check").on("change", function () {
      if ($(this).is(":checked")) {
        $("a[type='submit']").removeClass("disabled"); //enable input
        $("button[type='submit']").removeAttr("disabled"); //enable input
      } else {
        $("a[type='submit']").addClass("disabled"); //enable input
        $("button[type='submit']").attr("disabled", true); //disable input
      }
    });
  }

  if ($(".js-responsive-tab").length > 0) {
    $(".js-responsive-tab").on("shown.bs.tab", function (event) {
      const activeTab = $(this).find(".tab-pane.active").attr("id");
      $(this)
        .find(".js-select-tab")
        .find("option[value='#" + activeTab + "']")
        .attr("selected", "selected");
    });
  }

  if ($('[data-bs-toggle="tooltip"]').length > 0) {
    $('[data-bs-toggle="tooltip"]').tooltip()
  }
  

  if ($("#filePhoto").length > 0) {
    var imageLoader = document.getElementById("filePhoto");
    imageLoader.addEventListener("change", handleImage, false);

    function handleImage(e) {
      var reader = new FileReader();
      reader.onload = function (event) {
        $(".img-preview").attr("src", event.target.result);
        $(".upload-container__default").addClass("d-none");
        $(".upload-container__replace").removeClass("d-none");
        $(".js-preview-container").removeClass("d-none");
      };
      reader.readAsDataURL(e.target.files[0]);
    }

    function handleImages(e) {
      $(".img-preview").remove();
      for (var i = 0; i < e.target.files.length; i++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          var $img = $(".img-preview");
          $img.attr("src", event.target.result);
          $(".js-preview-container").append($img);
        };
        reader.readAsDataURL(e.target.files[i]);
      }
    }
  }

  function setError(field, error_message, this_message, helper_container) {
    var $field__helper, $helper_container;
    if (helper_container == null) {
      helper_container = ".field-group";
    }
    $helper_container = $(helper_container);
    $field__helper = $(".field-helper");
    if (this_message === "" || typeof this_message === "undefined") {
      field.closest($helper_container).find($field__helper).html(error_message);
    } else {
      field.closest($helper_container).find($field__helper).html(this_message);
    }
    field
      .closest($helper_container)
      .removeClass("field-group--valid")
      .addClass("field-group--invalid");
  }

  function markValid(field, helper_container) {
    var $field__helper, $helper_container;
    if (helper_container == null) {
      helper_container = ".field-group";
    }
    $helper_container = $(helper_container);
    $field__helper = $(".field-helper");
    field
      .closest($helper_container)
      .removeClass("field-group--invalid")
      .addClass("field-group--valid");
    field.closest($helper_container).find($field__helper).html("");
  }

  function markInvalid(field, error_message) {
    var $field_wrapper;
    $field_wrapper = field.parents($inputWrapper);
    if ($field_wrapper.hasClass("field-group--invalid")) {
      setError(field, error_message);
    }
  }

  function inputRequired(input) {
    input.each(function () {
      var $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      if ($this.val() === "" || parseInt($this.val()) < 1) {
        setError($this, "This is required", $thisMessage);
      } else {
        markValid($this);
      }
    });
  }

  function selectRequired(select) {
    select.each(function() {
      var $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr('data-message');
      if ($this.val() === '') {
        setError($this, 'This is required', $thisMessage);
      } else {
        markValid($this);
      }
    });
  };

  function textAreaRequired(input) {
    input.each(function () {
      var $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      if ($this.val() === "") {
        setError($this, "This is required", $thisMessage);
      } else {
        markValid($this);
      }
    });
  }

  function inputEmail(select) {
    select.each(function () {
      var $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      if ($this.val() === "") {
        setError($this, "This is required", $thisMessage);
      } else {
        markValid($this);
      }
    });
  }

  function inputEmail(input_email) {
    input_email.each(function () {
      var $checkEmail, $this, $thisMessage, $valid_email;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      $valid_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,13}$/i;
      $checkEmail = $this.val().match($valid_email);
      if ($this.val() === "") {
        setError($this, "This is required.");
      } else if ($checkEmail === null) {
        setError($this, "Please enter a valid email.");
      } else {
        markValid($this);
      }
    });
  }

  function inputPassword(input_password) {
    input_password.each(function () {
      var $this, $thisLength;
      $this = $(this);
      $thisLength = $this.attr("data-min-length");
      if ($this.val() === "") {
        setError($this, "This is required.");
      } else if ($this.val().length < $thisLength) {
        setError(
          $this,
          "Please input at least " + $thisLength + " characters."
        );
      } else {
        markValid($this);
      }
    });
  }

  function inputPasswordConfirmation(input_password) {
    input_password.each(function () {
      var $this, $thisCompareTo, $thisLength;
      $this = $(this);
      $thisLength = $this.attr("data-min-length");
      $thisCompareTo = $this.attr("data-target");
      if ($this.val() === "") {
        setError($this, "This is required.");
      } else if ($this.val() !== $($thisCompareTo).val()) {
        setError($this, "Password did not match.");
      } else {
        markValid($this);
      }
    });
  }

  function inputCheckbox(input_checkbox) {
    input_checkbox.each(function () {
      var $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      if ($this.find("input[type='checkbox']:checked").length === 0) {
        setError($this, "This is required.", $thisMessage);
      } else {
        markValid($this);
      }
    });
  }

  function groupCheckbox(group_checkbox) {
    group_checkbox.each(function () {
      var $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      if ($this.find("input[type=checkbox]:checked").length === 0) {
        setError($this, "This is required.", $thisMessage);
      } else {
        markValid($this);
      }
    });
  }

  function inputRadio(input_radio) {
    input_radio.each(function () {
      var $helperContainer, $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      $helperContainer =
        $this.attr("data-field-helper-container") || ".field-container";
      if (
        $this.closest($helperContainer).find("input[type='radio']:checked")
          .length === 0
      ) {
        setError($this, "This is required.", $thisMessage, $helperContainer);
      } else {
        markValid($this, $helperContainer);
      }
    });
  }

  function inputNumber(input_number) {
    input_number.each(function () {
      var $checkNumber, $this, $thisMessage, $valid_number;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      $valid_number = /^\d+$/;
      $checkNumber = $valid_number.test($this.val());
      if ($this.val() === "") {
        setError($this, "This is required.");
      } else if ($checkNumber === false) {
        setError($this, "Please enter a valid number.");
      } else if (parseInt($this.attr("data-min-val")) > parseInt($this.val())) {
        setError($this, "Did not meet required minimum value.");
      } else {
        markValid($this);
      }
    });
  }

  function inputTel(input_tel) {
    input_tel.each(function () {
      var $checkTel, $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      $checkTel = $this.val().indexOf("_") > -1;
      if ($this.val() === "") {
        setError($this, "This is required.");
      } else if ($checkTel === true) {
        setError($this, "Please enter a valid telephone number.");
      } else {
        markValid($this);
      }
    });
  }

  function inputDate(input_date) {
    input_date.each(function () {
      var $checkDate, $this, $thisMessage;
      $this = $(this);
      $thisMessage = $this.attr("data-message");
      $checkDate = true;
      if ($this.val().indexOf("m") > -1) {
        $checkDate = true;
      } else if ($this.val().indexOf("d") > -1) {
        $checkDate = true;
      } else if ($this.val().indexOf("y") > -1) {
        $checkDate = true;
      } else {
        $checkDate = false;
      }
      if ($this.val() === "") {
        setError($this, "This is required.");
      } else if ($checkDate === true) {
        setError($this, "Please enter a valid date.");
      } else {
        markValid($this);
      }
    });
  }

  if (window.location.pathname === '/account/my-benefits/healthcare' && $("#benefitsModal").length > 0) {
    var selectedBenefit = localStorage.getItem('selectedBenefit');
    
    if (selectedBenefit !== '/account/my-benefits/healthcare') {
      $('#benefitsModal').modal('show');
    }
  } else {
    localStorage.removeItem('selectedBenefit');
  }
  

  if($("#enrollment-birthdate").length > 0 ) {
    let today = new Date();
    let options = { timeZone: 'Asia/Manila' };
    let manilaDate = today.toLocaleString('en-US', options);
    var updatedDate = new Date(manilaDate);
    flatpickr($("#enrollment-birthdate"), {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      defaultDate: new Date(),
      appendTo: window.document.querySelector('.date__wrapper'),
      position: "below",
      positionElement: $(".date__wrapper"),
      maxDate: updatedDate.setDate(updatedDate.getDate()),
    });
  }

  if($("#guarantee_letter_date_of_visit").length > 0 ) {
    let today = new Date();
    let options = { timeZone: 'Asia/Manila' };
    let manilaDate = today.toLocaleString('en-US', options);
    var updatedDate = new Date(manilaDate);
    flatpickr($("#guarantee_letter_date_of_visit"), {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      defaultDate: new Date(),
      appendTo: window.document.querySelector('.date__wrapper'),
      position: "below",
      positionElement: $(".date__wrapper"),
      minDate: updatedDate.setDate(updatedDate.getDate() + 3),
      maxDate: updatedDate.setFullYear(updatedDate.getFullYear() + 2),
    });
  }
  
  if($("#call_request_requested_date").length > 0 ) {
    let today = new Date();
    let options = { timeZone: 'Asia/Manila' };
    let manilaDate = today.toLocaleString('en-US', options);
    var updatedDate = new Date(manilaDate);
    flatpickr($("#call_request_requested_date"), {
      enableTime: true,
      altInput: true,
      altFormat: "F j, Y (G:i K)",
      dateFormat: "Y-m-d H:i",
      defaultDate: new Date(),
      appendTo: window.document.querySelector('.date__wrapper'),
      position: "below",
      positionElement: $(".date__wrapper"),
      minDate: updatedDate.setDate(updatedDate.getDate() + 2),
      maxDate: updatedDate.setFullYear(updatedDate.getFullYear() + 2),
    });
  }

  if($(".js--toggle-password").length > 0 ) {
    const passwordInput = document.getElementById('password-input');
    const toggleButton = document.querySelector('.js--toggle-password');
    const iconEyeShow = toggleButton.querySelector('.icon-eye-show');
    const iconEyeHide = toggleButton.querySelector('.icon-eye-hide');

    toggleButton.addEventListener('click', function() {
      const isPasswordVisible = passwordInput.type === 'text';
      passwordInput.type = isPasswordVisible ? 'password' : 'text';
      iconEyeShow.classList.toggle('d-none', isPasswordVisible);
      iconEyeHide.classList.toggle('d-none', !isPasswordVisible);
    });
  }


  if ($(".button--validate").length > 0) {
    $(".button--validate:not(:disabled)").on("click", function (e) {
      var _this, _thisForm;
      e.preventDefault();
      _this = $(this);
      _thisForm = _this.closest("form");
      if (_thisForm.find($('input[data-validate="required"]')).length > 0) {
        inputRequired(_thisForm.find($('input[data-validate="required"]')));
      }
      if (
        _thisForm.find($('input[type="hidden"][data-validate="required"]'))
          .length > 0
      ) {
        inputRequired(
          _thisForm.find($('input[type="hidden"][data-validate="required"]'))
        );
      }
      if (_thisForm.find($('textarea[data-validate="required"]')).length > 0) {
        textAreaRequired(
          _thisForm.find($('textarea[data-validate="required"]'))
        );
      }
      if (
        _thisForm.find($('select[data-validate="select-required"]')).length > 0
      ) {
        selectRequired(
          _thisForm.find($('select[data-validate="select-required"]'))
        );
      }
      if (_thisForm.find($('input[data-validate="email"]')).length > 0) {
        inputEmail(_thisForm.find($('input[data-validate="email"]')));
      }
      if (_thisForm.find($('input[data-validate="number"]')).length > 0) {
        inputNumber(_thisForm.find($('input[data-validate="number"]')));
      }
      if (_thisForm.find($('input[data-validate="password"]')).length > 0) {
        inputPassword(_thisForm.find($('input[data-validate="password"]')));
      }
      if (
        _thisForm.find($('input[data-validate="password_confirmation"]'))
          .length > 0
      ) {
        inputPasswordConfirmation(
          _thisForm.find($('input[data-validate="password_confirmation"]'))
        );
      }
      if (_thisForm.find($('[data-validate="checkbox"]')).length > 0) {
        inputCheckbox(_thisForm.find($('[data-validate="checkbox"]')));
      }
      if (_thisForm.find($('[data-validate="radio"]')).length > 0) {
        inputRadio(_thisForm.find($('[data-validate="radio"]')));
      }
      if (_thisForm.find($('input[data-validate="tel"]')).length > 0) {
        inputTel(_thisForm.find($('input[data-validate="tel"]')));
      }
      if (_thisForm.find($('input[data-validate="date"]')).length > 0) {
        inputDate(_thisForm.find($('input[data-validate="date"]')));
      }
      if (_thisForm.find(".field-group--invalid").length === 0) {
        _this.addClass("disabled");
        _this.prop("disabled", true);
        _this
          .html(
            '<span class="loader-ring"><div></div><div></div><div></div><div></div></span>'
          )
          .keyup();
        if (_thisForm.data("remote")) {
          Rails.fire(_thisForm[0], "submit");
        } else {
          _thisForm.submit();
        }
        return true;
      }
      setTimeout(function () {
        $("html, body").animate(
          {
            scrollTop:
              _thisForm.find(".field-group--invalid").first().offset().top -
              $("nav").outerHeight() -
              36 -
              30,
          },
          500
        );
        _thisForm.find(".field-group--invalid").first().find(".field").focus();
      }, 300);
      return false;
    });
    return $(document).on("change", ".js-check-disclaimer", function (e) {
      if ($(this).is(":checked")) {
        return $(".button--validate")
          .removeClass("disabled")
          .attr("disabled", false);
      } else {
        return $(".button--validate")
          .addClass("disabled")
          .attr("disabled", true);
      }
    });
  }

  function checkPasswordValidation(value) {
    let $error = null;
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(value)) {
      $error = "Password must not contain Whitespaces.";
      $('.pw-error1').addClass('text-danger').removeClass('text-success')
    } else {
      $('.pw-error1').removeClass('text-danger').addClass('text-success')
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(value)) {
      $error = "Password must have at least one Uppercase Character.";
      $('.pw-error2').addClass('text-danger').removeClass('text-success')
    } else {
      $('.pw-error2').removeClass('text-danger').addClass('text-success')
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(value)) {
      $error = "Password must have at least one Lowercase Character.";
      $('.pw-error3').addClass('text-danger').removeClass('text-success')
    } else {
      $('.pw-error3').removeClass('text-danger').addClass('text-success')
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(value)) {
      $error = "Password must contain at least one Digit.";
      $('.pw-error4').addClass('text-danger').removeClass('text-success')
    } else {
      $('.pw-error4').removeClass('text-danger').addClass('text-success')
    }

    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    if (!isContainsSymbol.test(value)) {
      $error = "Password must contain at least one Special Symbol.";
      $('.pw-error5').addClass('text-danger').removeClass('text-success')
    } else {
      $('.pw-error5').removeClass('text-danger').addClass('text-success')
    }

    const isValidLength = /^.{8,100}$/;
    if (!isValidLength.test(value)) {
      $error = "Password must be 10-16 Characters Long.";
      $('.pw-error6').addClass('text-danger').removeClass('text-success')
    } else {
      $('.pw-error6').removeClass('text-danger').addClass('text-success')
    }

    return $error;
  }

  if ($(".js-select-tab").length > 0) {
    $('.js-select-tab').on('change', function (e) {
      $('.tab-healthcenter button').eq($(this).val()).tab('show');
    });
  }

  if ($(".survey__form").length > 0) {
    var currentTab = 0; 
    showTab(currentTab); 
    
    function showTab(n) {
      var x = document.querySelectorAll(".tab");
      x[n].style.display = "block";
      if (n == 0) { 
        document.querySelector("#surveyPrev").style.display = "none";
        document.querySelector(".survey__btnGroup").classList.remove("justify-content-between");
      } else {
        document.querySelector("#surveyPrev").style.display = "inline";
        document.querySelector(".survey__btnGroup").classList.add("justify-content-between");
      }
      if (n == (x.length - 1)) {
        document.querySelector("#surveyNext span").innerHTML = "Submit Feedback";
      } else {
        document.querySelector("#surveyNext span").innerHTML = "Next";
      }
      fixStepIndicator(n)
    }

    function validateForm() {
      let valid = true;
      if (valid) {
        document.getElementsByClassName("step")[currentTab].className += " finish";
      }
      return valid; 
    }

    function nextPrev(n) {
      var x = document.querySelectorAll(".tab");
      if (n == 1 && !validateForm()) return false;
      x[currentTab].style.display = "none";
      currentTab = currentTab + n;
      // if (currentTab >= x.length) {
      //   document.querySelector("#survey__form").submit();
      //   return false;
      // }
      if(x[currentTab].classList.contains('finish')) {
        $("#surveyNext").attr("disabled", false);
      } else {
        $("#surveyNext").attr("disabled", true);
      }
      showTab(currentTab);
    }

    function fixStepIndicator(n) {
      var i, x = document.querySelectorAll(".step");
      for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(" active", "");
      }
      x[n].className += " active";
    }

    let radioTabs = ['.tab1 input, .tab2 input, .tab3 input, .tab5 input'];
    let inputTabs = ['.tab4 input.field, .tab6 input.field'];

    function validation() {
      radioTabs.map(function(i) {
        $(i).change(function () {
          $("#surveyNext").attr("disabled", false);
          document.getElementsByClassName("tab")[currentTab].className += " finish";
        });
      })
      inputTabs.map(function(i) {
        $(i).keyup(function() {
          if ( $(i).val() != ""  ) {
            $("#surveyNext").attr("disabled", false);
            document.getElementsByClassName("tab")[currentTab].className += " finish";
          } else {
            $("#surveyNext").attr("disabled", true);
          }
        });
      });
    }
    $("#surveyPrev").on("click", function (e) {
      nextPrev(-1);
      $("#surveyNext").attr("disabled", false);
    });
    
    $("#surveyNext").on("click", function (e) {
      document.querySelector("#survey__form").submit();
      nextPrev(1);
    });

    validation();

    $(".survey__form input").on("click", function (e) {
      $('.others:not(:checked)').parents().eq(2).next().addClass("d-none");
      $('.others:checked').parents().eq(2).next().removeClass("d-none");
    });
  };


  if ($(".resources__list").length > 0) {
      $(".resources__list").hover(function(){
        $(".resources__list").addClass("resources__list--inactive"); 
        $(this).addClass("resources__list--active"); 
      }, function () {
        $(".resources__list").removeClass("resources__list--inactive");
        $(this).removeClass("resources__list--active");
      });
  };

  if ($(".rating__form").length > 0) {
    $('.tab1 input').on("change", function () {
      $("#ratingSubmit").attr("disabled", false);
    });
    $('#ratingSubmit').on("click", function () {
      document.querySelector('#rating__form').submit();
      $('.rating__section').remove();
      $(".thank-you").css("display", "flex");
    });
  };


  return $(document).on("keyup", ".js-password-checker", function (e) {
    let $this = $(this)
    let $error = checkPasswordValidation($this.val())

    if ($error == null) {
      $('.js-password-submit').attr('disabled', false)
    }
    else {
      $('.js-password-submit').attr('disabled', true)
    }
  });


});

global.startDriving = function(name) {
    const params = new URLSearchParams(window.location.search)
    const locationPath = window.location.pathname

    if( locationPath === "/account/dashboard" && params.get('step') === '1') {
      const driver = new Driver({
        className: 'step1',
        overlayClickNext: true,
        showButtons: true,
        allowClose: false,
      });
      let text1 = `Welcome to your MyBenefits Dashboard, `+name+`! In here, you can access everything you need to know about your ALLCARE benefits and how to avail them.

      Let’s get started.

      Click here!
      `;
      const getStarted = {
        element: '#user_dropdown',
        popover: {
          className: 'tour1',
          title: 'test',
          description: text1,
          position: 'bottom',
          showButtons: true,
        },
        onNext: () => {
          driver.preventMove();
          $('#user_dropdown').dropdown('toggle');
          setTimeout(() => {
            driver.moveNext();
          }, 250);
        }
      };
      let text2 = `This is where you can find your account information, including:
      • Membership status
      • Company name
      • Your name
      • Your Card ID Number
      • Membership expiration date
      `;
      const showDropdown = {
        element: '.dropdown-menu',
        popover: {
          className: 'tour1',
          title: 'test',
          description: text2,
          position: 'bottom',
          showButtons: true,
          allowClose: false,
          overlayClickNext: false,
        },
        onNext: () => {
          driver.preventMove();
          $('#user_dropdown').dropdown('toggle');
          setTimeout(() => {
            driver.moveNext();
          }, 400);
        }
      };
      const showCardId = {
        element: '#cardID',
        popover: {
          className: 'tour6',
          title: 'Title on Popover',
          description: 'This is your HMO card number. Etiqa is ALLCARE’s HMO partner. When availing your HMO benefits, kindly inform the hospital that your HMO is Etiqa. ',
          position: 'bottom',
          showButtons: true,
          allowClose: true,
          overlayClickNext: false,
        },
        onHighlightStarted: () => {
          $('#driver-highlighted-element-stage').addClass('driver--border');
        },
        onNext: () => {
          driver.preventMove();
          $('#user_dropdown').dropdown('toggle');
          $('#driver-highlighted-element-stage').removeClass('driver--border');
          setTimeout(() => {
            driver.moveNext();
          }, 150);
        }
      }
      const updateAccount = {
        element: '#viewAccount',
        popover: {
          className: 'tour5',
          title: 'Title on Popover',
          description: 'Now, let’s update your account details. Click <b>View my Account</b>',
          position: 'bottom',
          showButtons: false,
          allowClose: true,
          overlayClickNext: false,
        },

        onHighlightStarted: () => {
          $('#driver-highlighted-element-stage').addClass('driver--border');
        },
        onNext: () => {
          driver.preventMove();
          $('#user_dropdown').dropdown('toggle');
          $('#driver-highlighted-element-stage').removeClass('driver--border');

          setTimeout(() => {
            driver.preventMove();
            driver.reset();
            setTimeout(() => {
              window.location.href = '/account/profile?step=2';
            }, 100);
          }, 150);
        }
      }
      driver.defineSteps([
        getStarted,
        showDropdown,
        showCardId,
        updateAccount
      ]);
      driver.start();
    }
    if( locationPath === "/account/profile"  && params.get('step') === '2') {
      const driver = new Driver({
        overlayClickNext: false,
        showButtons: true,
        allowClose: false,
      });
      const showEditProfile = {
        element: '#editProfile',
        overlayClickNext: false,
        popover: {
          className: 'tour6',
          title: 'test',
          description: "Click Edit Profile to update your information.",
          position: 'bottom',
          showButtons: false,
        },
        onHighlighted: () => {
          $('#editProfile').on("click", function() {
            setTimeout(() => {
              driver.moveNext();
            }, 600);
          });
        },
      }

      const showSaveChanges = {
        element: '#modalEditProfile .modal-content',
          popover: {
            className: 'tour7',
            title: 'Title on Popover',
            description: `Is your information correct and complete?
            If yes, click <b>Save Changes</b>`,
            position: 'bottom',
            showButtons: false,
          },
          onHighlighted: () => {
            $('#member-profile-form').submit(function(e) {
              e.preventDefault();
              // alert('asd')
              $('#modalEditProfile').modal('hide')
              driver.moveNext();
            });
          },
          onNext: () => {
            driver.preventMove();
            driver.moveNext();
          }
      }

      const showDashboard = {
        element: '#tabBar .tab__list:first-of-type a',
        popover: {
          className: 'tour8',
          title: 'Title on Popover',
          description: 'Let’s go back to the MyBenefits Dashboard. Click the Dashboard tab.',
          position: 'top',
          showButtons: false,
        },
        onHighlighted: () => {
          $('#tabBar').addClass('position-absolute');
          $('#tabBar .tab__list:first-of-type a').click(function(e) {
            e.preventDefault();
            window.location.href = '/account/dashboard?step=3';
          });
        },
        onNext: () => {
          driver.preventMove();
          $('#tabBar').removeClass('position-absolute');
          window.location.href = '/account/dashboard?step=3';
        }
      }
      driver.defineSteps([
        showEditProfile,
        showSaveChanges,
        showDashboard,
      ]);
      driver.start();
    }
    if( locationPath === "/account/dashboard"  && params.get('step') === '3') {
        const driver = new Driver({
        overlayClickNext: true,
        showButtons: true,
        allowClose: false,
      });
      const showDashboard = {
        element: '#tabBar',
        popover: {
          className: 'tour8',
          title: 'Title on Popover',
          description: 'These icons will be your new best friends! Time to get to know them.',
          position: 'top',
          showButtons: true,
        },
        onHighlighted: () => {
          $('#tabBar').addClass('position-absolute');
          $('#tabBar').addClass('disable-link');
        },
        onNext: () => {
          driver.preventMove();
          $('#tabBar').removeClass('position-absolute');
          driver.moveNext();
        }
      }

      const showBenefits = {
        element: '#tabBar .tab__list:nth-of-type(2) a',
        popover: {
          className: 'tour9',
          title: 'Title on Popover',
          description: '<b>Benefits</b> will give you an overview of your full benefits coverage. Let’s check it!',
          position: 'top',
          showButtons: true,
        },
        onHighlighted: () => {
          $('#tabBar').addClass('position-absolute');
        },
        onNext: () => {
          driver.preventMove();
          $('#tabBar').removeClass('position-absolute');
          driver.moveNext();
        }
      }

      const showRequestLog = {
        element: '#tabBar .tab__list:nth-of-type(3) a',
        popover: {
          className: 'tour10',
          title: 'Title on Popover',
          description: '<b>Request</b> allows you to request a Letter of Guarantee from Etiqa and schedule an appointment with an accredited hospital or partner doctor. Requests must be sent not less than 24 hours prior to date of visit.',
          showButtons: true,
        },
        onHighlighted: () => {
          $('#tabBar').addClass('position-absolute');
        },
        onNext: () => {
          driver.preventMove();
          $('#tabBar').removeClass('position-absolute');
          driver.moveNext();
        }
      }

      const clickBenefits = {
        element: '#tabBar .tab__list:nth-of-type(2) a',
        popover: {
          className: 'tour9',
          title: 'Title on Popover',
          description: 'Now that you know them. Let’s click <b>Benefits</b>',
          position: 'top',
          showButtons: true,
        },
        onHighlighted: () => {
          $('#tabBar').removeClass('disable-link');
          $('#tabBar').addClass('position-absolute');
        },
        onNext: () => {
          driver.preventMove();
          $('#tabBar').removeClass('position-absolute');
           window.location.href = '/account/my-benefits/healthcare';
        }
      }
      driver.defineSteps([
        showDashboard,
        showBenefits,
        showRequestLog,
        clickBenefits
      ]);
      driver.start();
    }
};

document.addEventListener("DOMContentLoaded", () => {
  const idTypeSelect = document.querySelector('[data-id-type="select"]');
  const idNumberInput = document.querySelector('[data-id-number="input"]');

  // Define masks and placeholders for each ID type
  const formats = {
    1: { mask: 'A00-00-000000', placeholder: 'A01-23-456789' }, // Driver's License (Alphanumeric with dash)
    2: { mask: 'P0000000A', placeholder: 'P1234567A' },         // Passport (Alphanumeric with fixed start and end)
    3: { mask: '0000-0000-0000-0000', placeholder: '1234-5678-9012-3456' }, // Voter's ID (Numeric with dashes)
    4: { mask: '0000-0000-0000', placeholder: '1234-5678-9012' },           // UMID (Numeric)
    5: { mask: '000000-00-0000000000', placeholder: '123456-78-9012345678' }, // Birth Certificate (Numeric with dashes)
    6: { mask: '0000-0000-0000', placeholder: '1234-5678-9012' },           // National ID (Numeric)
    7: { mask: 'XX-0000-0000-XX', placeholder: 'PH-1234-5678-ZZ' }          // Postal ID (Alphanumeric with optional parts)
  };

  let maskInstance; // Variable to hold the active mask instance

  // Function to apply/update mask and placeholder based on the selected ID type
  const updateInputMaskAndPlaceholder = () => {
    const selectedType = idTypeSelect.value; // Get selected ID type
    const format = formats[selectedType]; // Get format for selected type

    if (maskInstance) {
      maskInstance.destroy(); // Destroy the existing mask instance, if any
    }

    if (format) {
      idNumberInput.disabled = false;
      // Apply the mask for the selected format
      maskInstance = IMask(idNumberInput, { 
        mask: format.mask, 
        definitions: {
          'A': /[A-Za-z]/,  // Letter
          '0': /[0-9]/,      // Digit
          'X': /[A-Za-z0-9]/ // Alphanumeric
        }
      });
      // Set the placeholder for the selected format
      idNumberInput.setAttribute("placeholder", format.placeholder);
    } else {
      idNumberInput.disabled = true;
      // Clear mask and placeholder if no ID type is selected
      idNumberInput.setAttribute("placeholder", "Enter ID number");
    }
  };

  // Add event listener to the "ID Type" select field
  idTypeSelect.addEventListener("change", updateInputMaskAndPlaceholder);

  // Optional: Initialize the mask and placeholder on page load if a type is pre-selected
  updateInputMaskAndPlaceholder();
});


$("document").ready(function () {
  // Cookie policy
  var nonce = $('[data-cookie-nonce]').data('cookie-nonce');
  var $cookieFooter = $('[data-cookie-nonce]').eq(0);
  if ($cookieFooter) {
    var $allowAllButton = $('#allowAllButton');
    var $cookiePopup = $('#modalCookie');
    var $saveSettingsButton = $cookiePopup.find('#save-settings');
    var $analyticsCheckbox = $cookiePopup.find('#advertising_cookie');
    var bootstrapModal = new Modal($cookiePopup[0]);
    checkInitialRender();

    if ($allowAllButton.length) {
      $allowAllButton.on('click', function() {
        localStorage.setItem('isAcceptingAnalytics', '1');
        document.cookie = "is_accepting_analytics=1; path=/";
        hideCookieFooter();
        renderGtag();
      });
    }

    $saveSettingsButton.on('click', function() {
      var isAcceptingAnalytics = $analyticsCheckbox.is(':checked') ? '1' : '0';
      localStorage.setItem('isAcceptingAnalytics', isAcceptingAnalytics);
      document.cookie = `is_accepting_analytics=${isAcceptingAnalytics}; path=/`;
      if (isAcceptingAnalytics == '1') {
        renderGtag();
        hideCookieSettingsModal();
        hideCookieFooter();
        bootstrapModal.hide(); 
      } else {
        bootstrapModal.hide(); 
      }
    });

    function checkInitialRender() {
      if (localStorage.getItem('isAcceptingAnalytics') === '1') {
        renderGtag();
        hideCookieFooter();
      } else {
        showCookieFooter();
      }
    }

    function hideCookieFooter() {
      $cookieFooter.hide().removeClass('cookie--fade');
    }

    function showCookieFooter() {
      $cookieFooter.css({
        'z-index': '1039',
        'display': 'block'
      });
    }

    function renderGtag() {
      if (localStorage.getItem('isAcceptingAnalytics') === '1') {
        if (!$('#gtag-script').length) {
          // Load Google Tag Manager
          ['GTM-KCTZGM8', 'GTM-5N4NTD9', 'GTM-NJJST58W'].forEach(function(id) {
            var gtmScript = document.createElement('script');
            gtmScript.id = 'gtag-script-' + id;
            gtmScript.async = true;
            gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
            gtmScript.setAttribute('nonce', nonce);
            document.head.appendChild(gtmScript);

            // Add noscript iframe
            var noscript = document.createElement('noscript');
            noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
            document.body.appendChild(noscript);
          });

          // Load Google Analytics
          var gaScript = document.createElement('script');
          gaScript.id = 'gtag-script';
          gaScript.async = true;
          gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-74787146-35';
          gaScript.setAttribute('nonce', nonce);
          document.head.appendChild(gaScript);

          var gaInlineScript = document.createElement('script');
          gaInlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-74787146-35');
          `;
          gaInlineScript.setAttribute('nonce', nonce);
          document.head.appendChild(gaInlineScript);

          // Load AdWords
          var awScript = document.createElement('script');
          awScript.id = 'aw-script';
          awScript.async = true;
          awScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-728743803';
          awScript.setAttribute('nonce', nonce);
          document.head.appendChild(awScript);

          var awInlineScript = document.createElement('script');
          awInlineScript.innerHTML = `
            gtag('config', 'AW-728743803');
          `;
          awInlineScript.setAttribute('nonce', nonce);
          document.head.appendChild(awInlineScript);

          // Load Facebook Pixel
          var fbqScript = document.createElement('script');
          fbqScript.innerHTML = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '580916772739143');
            fbq('track', 'PageView');
          `;
          fbqScript.setAttribute('nonce', nonce);
          document.head.appendChild(fbqScript);
        }
      }
    }

    function hideCookieSettingsModal() {
      $cookiePopup.hide();
    }
  }
});
